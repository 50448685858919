@import '../../../../styles/variables.scss';

@keyframes open {
	from {
		max-height: 20%;
	}

	to {
		max-height: 100%;
	}
}

.accordion {
	position: relative;

	overflow: hidden;

	padding: 20px;

	cursor: pointer;
	-webkit-transition: max-height 0.4s;

	@include primary-border;

	@media (max-width: 475px) {
		padding: 10px;
	}

	&.active {
		max-height: 100%;

		animation: open 0.4s ease-in-out;
	}

	.text {
		color: $grey;

		font-size: 14px;
		font-weight: 300;
		line-height: 26px;
	}

	.header {
		display: grid;
		align-items: center;
		grid-template-columns: auto 1fr;
		gap: 20px;

		.icon {
			display: flex;
			align-items: center;

			width: 100%;

			transition: all 0.4s;
			transform: rotate(180deg);

			&.active {
				transform: rotate(0);
			}
		}

		.title {
			height: max-content !important;

			font-size: 16px;
			font-weight: 500;

			@media (max-width: 425px) {
				font-size: 14px;
			}
		}
	}

	.description {
		display: flex;
		align-items: center;

		height: max-content;
		padding: 14px 45px 0 45px;

		line-height: 23px;

		@media (max-width: 425px) {
			padding: 10px;
		}

		h1 {
			margin-bottom: 16px;

			font-size: 40px;
		}

		h2 {
			margin-bottom: 16px;

			font-size: 35px;
		}

		h3 {
			margin-bottom: 16px;

			font-size: 30px;
		}

		p {
			font-style: italic;

			a {
				text-decoration: underline;
			}
		}
	}
}
