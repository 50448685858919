@import '../../../../styles/variables.scss';

.link {
	color: $grey;

	font-size: 14px;
	line-height: 21px;
}

.common {
	cursor: pointer;
	transition: all 0.4s;

	&:hover {
		text-decoration: none;

		background: $hover-primary;
		-webkit-background-clip: text !important;
		-moz-background-clip: text !important;
		-webkit-text-fill-color: transparent !important;
		-moz-text-fill-color: transparent !important;
	}
}

.router {
	color: $white;

	font-size: 16px;
}
