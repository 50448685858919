@import '../../../../styles/variables.scss';

.textField {
	padding: 10px 0;

	color: $grey;

	&.primary {
		@include primary-border;

		&.input {
			input {
				width: 100%;
				height: 100%;
				padding: 0 10px;
			}

			&::after {
				display: none;
			}
		}
	}

	&.input {
		color: #000;

		font-weight: 600;

		&::after {
			display: block;

			padding-top: 5px;

			content: ' ';

			border-bottom: 2px solid $grey;
		}

		input {
			width: 100%;

			&::placeholder {
				color: $grey;

				font-size: 14px;
				font-weight: 600;
			}
		}
	}

	&.textarea {
		width: 300px;
		height: 200px;

		textarea {
			width: 100%;
			height: 100%;

			resize: none;

			background: transparent;

			&::placeholder {
				color: $grey;

				font-weight: 600;
			}
		}
	}
}
