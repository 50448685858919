@import '../../../../styles/_variables.scss';

.subscribe {
	position: fixed;
	z-index: 5;
	top: 20vh;
	right: -300px;

	display: grid;
	overflow: hidden;

	width: 300px;
	height: 200px;
	padding: 20px;

	transition: all 0.4s;

	border: 1px solid #FFF;
	border-top-left-radius: 8px;
	border-bottom-left-radius: 8px;
	background: transparent;
	gap: 20px;
	grid-template-columns: auto 1fr;

	@media (max-width: 768px) {
		top: 12vh;
		right: -310px;

		height: 150px;
	}

	&.openSubs {
		right: -2px;

		background-color: #FFF;

		@media (max-width: 768px) {
			width: 250px;
			height: 150px;
		}
	}

	h4 {
		display: contents;
		display: inline-block;

		margin-left: -10px;

		cursor: pointer;
		transition: all 0.4s;
		transform: rotate(180deg);
		text-align: center;

		color: #FFF;
		writing-mode: vertical-lr;

		@media (max-width: 768px) {
			font-size: 12px;
		}

		&.openSubs {
			color: #000;
		}
	}

	.subscribeBlock {
		display: grid;

		padding: 0 0 0 20px;

		transition: all 0.4s;
		grid-template-rows: auto;

		&.ok {
			transform: translateX(350px);
		}

		.button {
			width: 100%;

			cursor: pointer;
			transition: all 0.2s;

			border-radius: 8px;
			background: $primary;

			&:hover {
				background: $hover-primary;
			}
		}
	}

	.recaptcha {
		position: absolute;
		bottom: -20px;

		transition: all 0.4s;
		transform: translateX(350px);

		border-bottom-left-radius: 8px;

		&.active {
			transform: translateX(-350px);
		}

		&.reset {
			transform: translateX(0);
		}
	}

	.status {
		position: absolute;

		display: flex;
		align-items: center;
		justify-content: center;

		width: 100%;
		height: 100%;

		transition: all 0.4s;
		transform: translateX(-350px);

		background-color: #FFF;

		font-weight: bold;

		&.error {
			color: red;
		}

		&.success {
			color: green;
		}
	}
}