@import '../../../../styles/variables.scss';

.news {
	min-height: 600px;
	margin-top: 50px;

	.title {
		position: relative;

		max-width: 1440px;
		margin: 0 auto;

		text-align: center;

		font-size: 50px;
		font-weight: normal;
		line-height: 46px;

		&::before {
			@include bg-dots;
		}

		@media (max-width: 425px) {
			font-size: 40px;
		}
	}

	.body {
		display: grid;

		margin: 40px 0;

		transition: all 0.4s;
		grid-template-columns: 1fr 1fr;
		gap: 40px;

		@media (max-width: 1024px) {
			grid-template-columns: 1fr;
		}
	}
}
