@import '../../../../styles/variables.scss';

.virtualOffice {
    display: grid;
    justify-self: center;
    position: relative;
    z-index: -2;

    .firstDots {
        position: relative;
        width: 100vw;
        margin-top: -30px;

        &::after {
            top: 10%;
            right: 0;
            @include rect-2-right;
        }

        &::before {
            bottom: 0;
            left: 0;
            @include rect-2-left;
        }
    }

    .secondDots {
        position: relative;
        width: 100vw;
        margin: -100px 0;

        &::after {
            bottom: 10%;
            left: 0;
            @include rect-2-left;
        }

        &::before {

            top: 10%;
            right: 0;
            @include rect-2-right;
        }
    }

    .thirdDots {
        position: relative;
        width: 100vw;
        margin: -100px 0;

        &::after {
            top: 10%;
            right: 0;
            @include rect-2-right;
        }

        &::before {
            bottom: 40%;
            left: 0;
            @include rect-2-left;
        }
    }
}