.loader {
	display: inline-block;

	width: 80px;
	height: 80px;
}

.loader::after {
	display: block;

	width: 64px;
	height: 64px;
	margin: 8px;

	content: " ";
	animation: loader 1.2s linear infinite;

	border: 6px solid rgb(255, 0, 119);
	border-color: rgb(255, 0, 119) transparent rgb(255, 0, 119) transparent;
	border-radius: 50%;
}

@keyframes loader {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}
