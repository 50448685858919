.adminWrapper {
	.adminTitle {
		text-align: center;

		font-size: 50px;
	}

	.loading {
		position: fixed;
		z-index: 99999999;
		top: 0;
		left: 0;

		display: none;
		align-items: center;
		justify-content: center;

		width: 100vw;
		height: 100vh;

		background: rgb(0, 0, 0, 0.8);

		&.loadingActive {
			display: flex;
		}
	}

	input,
	textarea {
		width: 95%;
		margin: 10px 0;
		padding: 10px;

		color: #FFF;
		border: 1px solid #FFF;
		background: transparent;
	}

	input[type=date]::-webkit-datetime-edit {
		color: #999;
	}

	button {
		padding: 10px;

		cursor: pointer;
		transition: all 0.2s;

		color: #FFF;
		border: 1px solid #FFF;
		border-radius: 8px;

		&:hover {
			color: #000 !important;
			background: #FFF;
		}

		&.isSelected {
			color: #000 !important;
			background: #FFF;
		}
	}

	.toolbar {
		display: flex;
		justify-content: space-around;

		margin: 50px 0;
	}

	.body {
		display: grid;
		min-height: 400px;
		padding: 0 20px;
		grid-template-columns: 1fr 1fr;
		gap: 40px;

		.loginRender {
			margin: auto 0;

			button {
				width: 100px;
			}
		}

		.newsRender,
		.faqRender {
			display: block;

			button {
				margin: 10px 0;
			}

			button[type=submit] {
				width: 250px;
			}
		}
	}

	.allDataContainer {
		display: grid;
		overflow-y: scroll;

		width: 100%;
		height: 300px;
		margin-top: 20px;

		border: 1px solid #FFF;
		grid-auto-flow: row;
		grid-template-columns: 1fr 1fr 1fr;

		ul {
			margin: 10px;
			padding: 10px;

			font-size: 14px;

			li {
				.id {
					color: green;

					font-weight: bold;
				}

				.title {
					font-weight: bold;
				}
			}

			.delete {
				cursor: pointer;

				color: rgb(172, 0, 0);

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}
}