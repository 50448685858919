@import '../../../../styles/variables.scss';

.cryptoCurrency {
    display: grid;
    justify-self: center;

    .mining {}

    .farming {
        width: 100vw;

        position: relative;

        &::after {
            top: 50%;
            left: 0;
            @include rect-2-left;
        }

        &::before {
            position: absolute;
            background: #100521;
            width: 100%;
            height: 100%;
            content: "";
            z-index: -1;
        }
    }

    .everything {

        position: relative;
        width: 100vw;

        &::after {
            top: 50%;
            left: 0;
            @include rect-2-left;
        }

        &::before {
            @include rect-2-right;
            top: -8%;
            right: 0;
        }
    }
}