@import '../../../../styles/_variables.scss';

.header_burger {
	display: none;

	margin-right: 1rem;
	margin-left: sizephone(25);

	cursor: pointer;

	@media (max-width: 768px) {
		&.active::before {
			top: 9px;

			transform: rotate(45deg);
		}

		&.active {
			height: 19.5px;

			span {
				transform: scale(0);
			}
		}

		&.active::after {
			bottom: 8px;

			transform: rotate(-45deg);
		}

		position: relative;
		z-index: 5;

		display: block;

		width: 20px;
		height: 17px;

		&::before,
		&::after {
			position: absolute;
			left: 0;

			width: 100%;
			height: 2px;

			content: "";
			transition: all 0.3s ease 0s;

			background: #fff;
		}

		&::before {
			top: 0;
		}

		&::after {
			bottom: 0;
		}

		span {
			position: absolute;
			top: 8px;
			left: 0;

			width: 100%;
			height: 2px;

			transition: all 0.3s ease 0s;

			background: #fff;
		}
	}
}