@import '../../../../styles/variables.scss';

@keyframes open {
	from {
		max-height: 400px;
	}

	to {
		max-height: 100%;
	}
}

.post {
	position: relative;

	display: grid;
	justify-content: center;

	min-width: 600px;
	max-width: 600px;
	height: max-content;
	margin: 20px auto;
	max-height: 400px;
	transition: all 0.4s;
	grid-template-columns: 1fr;
	@include primary-border;

	&.isMore {
		max-height: max-content;
		// animation: open 0.4s ease-in-out;
	}

	@media (max-width: 768px) {
		min-width: 100%;
	}

	@media (max-width: 425px) {
		height: max-content;
		min-height: max-content;
	}

	.postPreview {
		overflow: hidden;

		transition: all 0.4s;
		word-break: break-all;

		.img {
			width: 100%;
			height: 200px;

			cursor: pointer;

			border-radius: 10px;
			border-bottom-left-radius: 0;
			background-repeat: no-repeat;
			background-position: center;
			background-size: 100%;
			border-end-end-radius: 0;
		}

		.date,
		.title {
			font-size: 15px;
			font-weight: 300;
			line-height: 21px;
		}

		.subDescr {
			padding: 30px;

			@media (max-width: 600px) {
				padding: 15px;
			}
		}

		.title {
			margin-top: 15px;

			font-size: 16px;
		}

		.deviver {
			margin: 20px 0;
		}

		.descr {
			word-break: break-all;

			h1 {
				margin-bottom: 16px;

				font-size: 40px;

				@media (max-width: 600px) {
					font-size: 30px;
				}
			}

			h2 {
				margin-bottom: 16px;

				font-size: 35px;

				@media (max-width: 600px) {
					font-size: 25px;
				}
			}

			h3 {
				margin-bottom: 16px;

				font-size: 30px;

				@media (max-width: 600px) {
					font-size: 20px;
				}
			}

			p {
				margin-bottom: 16px;

				a {
					text-decoration: underline;
				}
			}
		}
	}
}

.more {
	position: absolute;
	z-index: 999;
	bottom: -15px;
	left: 45%;

	padding: 5px 8px;

	cursor: pointer;
	transition: all 0.4s;
	transform: rotate(90deg);

	border-radius: 50% !important;
	background-color: #000;

	@include primary-border;

	&.opened {
		transform: rotate(-90deg);
	}
}