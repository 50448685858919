.img {
	width: 500px;
	height: 350px;
	margin: auto;

	@media (max-width: 700px) {
		width: 100%;
		height: 100%;
	}
}

.sliderWrapper {
	width: 70%;

	@media (max-width: 500px) {
		width: 100%;
	}
}

.container_rewright {
	margin-bottom: 100px !important;
}

.list {
	margin: -40px auto -40px auto;

	@media (max-width:640px) {
		margin: 0 auto;
	}
}