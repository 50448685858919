@import '../../../../styles/variables.scss';

.connectingBlockchain {
	display: grid;
	justify-content: center;

	max-width: 1024px;
	margin: 50px auto;

	img {
		width: 100%;
	}

	.title {
		&::after {
			@include bg-dots;
		}

		position: relative;

		max-width: 800px;
		margin: 0 auto;
		margin-bottom: 40px;

		text-align: center;

		font-size: 50px;
		font-weight: 300;
		line-height: 46px;

		@media (max-width: 425px) {
			font-size: 40px;
		}
	}

	.controlBlock {
		&::before {
			@include rect-2-right;
		}

		.description {
			display: grid;
			justify-content: center;
			color: $grey;
			margin-top: -70px;
			margin-bottom: 40px;

			@media (max-width: 425px) {
				margin-top: 0;
			}

			.list {
				display: grid;

				list-style-type: disc;

				font-size: 16px;
				font-weight: 300;
				line-height: 21px;
				grid-template-columns: auto auto;
				gap: 30px;

				li {
					max-width: 300px;
				}

				@media (max-width: 425px) {
					font-size: 14px;
				}
			}
		}
	}

	.ordersBlock {
		display: grid;
		justify-content: center;
		margin-top: 80px;


		@media (max-width: 768px) {
			margin-top: 40px;
		}

		@media (max-width: 425px) {
			margin-top: 20px;
		}

		&::before {
			@include rect-2-left;
		}

		.title {
			margin-bottom: 0;

			@media (max-width: 768px) {
				margin-bottom: 20px;
			}
		}

		.titleOrders {
			font-weight: 300;
			font-size: 25px;
			margin: auto;
			margin-bottom: 40px;
			text-align: center;
			margin-top: -40px;
			max-width: 800px;


			@media (max-width: 425px) {
				font-size: 20px;
				margin-top: 0;
			}
		}

		.description {
			@media (max-width: 425px) {
				font-size: 14px;
			}

			display: grid;
			max-width: 450px;
			margin: auto;
			color: $grey;
			font-size: 16px;
			font-weight: 300;
			line-height: 21px;
			gap: 25px;

			p {
				margin-left: -20px;
			}

			ul {
				display: grid;

				margin-top: 10px;

				list-style-type: disc;
				gap: 25px;
			}
		}
	}

	.incomeBlock {
		display: grid;
		justify-content: center;

		margin-top: 80px;

		&::before {
			@include rect-2-right;
		}

		.title {
			margin-bottom: -20px;
		}

		.description {
			@media (max-width: 425px) {
				font-size: 14px;
				margin-top: 0;
			}

			display: grid;

			max-width: 450px;
			margin: 0 auto;
			margin-top: -60px;

			color: $grey;

			font-size: 16px;

			font-weight: 300;
			line-height: 21px;
			gap: 25px;

			p {
				margin-left: -20px;
			}

			ul {
				display: grid;

				margin-top: 10px;

				list-style-type: disc;
				gap: 25px;
			}
		}
	}

	.safeBlock {
		display: grid;
		justify-content: center;

		margin-top: 80px;

		.description {
			@media (max-width: 425px) {
				font-size: 14px;
			}

			display: grid;

			max-width: 450px;
			margin: 0 auto;

			color: $grey;

			font-size: 16px;

			font-weight: 300;
			line-height: 21px;
			gap: 25px;

			p {
				margin-left: -20px;
			}

			ul {
				display: grid;

				margin-top: 10px;

				list-style-type: disc;
				gap: 25px;
			}
		}
	}
}