@import '../../../../../styles/variables.scss';

.wrapper {
	position: fixed;
	z-index: 10;
	top: -100vh;

	width: 100vw;
	height: 100vh;

	transition: all 0.4s;

	opacity: 1;
	background-color: rgba(0, 0, 0, 0.5);

	&.active {
		top: 0;

		opacity: 1;

		& .support {
			top: 50%;
		}
	}

	.close {
		position: absolute;
		top: 5px;
		right: 5px;

		display: none;

		@media (max-width: 600px) {
			display: block;
		}

		img {
			width: 20px;
		}
	}

	.support {
		position: fixed;
		z-index: 2;
		top: -100vh;
		left: 50%;

		display: flex;
		align-items: center;
		flex-direction: column;

		width: 30vw;
		height: max-content;
		margin: auto;
		padding: 40px;

		transition: all 0.4s;
		transform: translate(-50%, -50%);

		border: 1px solid $grey;
		border-radius: 8px;
		background-color: #000;

		@media (max-width: 768px) {
			width: 350px;
			padding: 20px;
		}

		@media (max-width: 600px) {
			width: 300px;
			padding: 10px;
		}
	}

	h2 {
		text-align: center;

		color: $grey;

		font-family: $font-fam;
		font-size: 25px;
		font-weight: bold;

		&.error {
			color: red;
		}

		&.success {
			color: green;
		}
	}

	.field {
		width: 100%;
		margin: 10px 0;
		padding: 10px 5px;

		border: 1px solid $grey;
		border-radius: 8px;

		font-size: 15px;

		input,
		textarea {
			color: $grey;

			font-family: $font-fam;
		}

		&::after {
			display: none;
		}
	}

	.preview_file {
		width: 100px;
		height: 100px;
		object-fit: contain;
	}

	.file {
		margin: 5px;

		font-weight: bold;

		&.ok {
			color: green;
		}

		&.error {
			color: red;
		}
	}

	.upload {
		display: block;

		width: 100%;
		margin-bottom: 10px;
		padding: 5px;

		cursor: pointer;

		border: 1px solid $grey;
		border-radius: 8px;
		background: $hover-primary;
		-webkit-background-clip: text !important;
		-moz-background-clip: text !important;
		-webkit-text-fill-color: transparent !important;
		-moz-text-fill-color: transparent !important;
	}

	.button {
		margin-top: 20px;
		padding: 10px;

		cursor: pointer;
		transition: all 0.2s;

		border-radius: 8px;
		background: $hover-primary;

		&:hover {
			background: $hover-primary;
		}
	}
}
