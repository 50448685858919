$white: #FFF;
$primary: -webkit-linear-gradient(106.69deg, #FFF500 -17.21%, #00FF5D 28.54%, #FF6BBE 58.35%);
$hover-primary:-webkit-linear-gradient(#6DD400 0%, #F7B500 58.1%);
$grey:#DDD9E3;
$font-fam: "Montserrat",
sans-serif;
$font-title: 'Gruppo',
sans-serif;
$border-primary:linear-gradient(#6DD400 0%, #F7B500 100%);
$moz-border-primary: -moz-linear-gradient(#6DD400 0%, #F7B500 100%);
$web-border-primary: -webkit-linear-gradient(#6DD400 0%, #F7B500 100%);

@mixin primary-border {
	border: 1px solid transparent;
	border-radius: 10px;
	background:
		linear-gradient(#000, #000) padding-box,
		$border-primary border-box;
}

@mixin primary-background {
	border-radius: 10px;
	background: linear-gradient(to right, #2E1F42 20%, #F62CA6 70%, #82FD17 80%, #DCF705 100%);

	@media(max-width:425px) {
		background: linear-gradient(to right, #2E1F42 20%, #F62CA6 70%, #82FD17 90%, #DCF705 100%);
	}
}

@mixin rect-2-left {
	position: absolute;
	z-index: -1;
	left: 0;

	display: block;

	width: 290px;
	padding-top: 34.9%;

	content: "";

	background: url('../images/bg-image-rect-2.svg') left center no-repeat;
	background-size: contain;
}

@mixin rect-2-right {
	position: absolute;
	right: 0;

	width: 267px;
	width: 18.6vw;
	padding-top: 40.9%;

	content: "";

	background: url("../images/bg-image-rect-1.svg") right center no-repeat;
	background-size: contain;
}

@mixin bg-dots {
	position: absolute;
	z-index: -1;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	width: 453px;
	height: 403px;
	margin: auto;

	content: "";

	background: url("../images/bg-dots-big.svg") center no-repeat;

	@media (max-width: 768px) {
		width: 100%;
	}
}