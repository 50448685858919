.line {
	width: 100%;

	&.grey {
		background: #3F3A49;
	}

	&.white {
		background-color: #FFF;
	}
}
