.sliderModal {
	position: fixed;
	z-index: 90999;
	top: -1000%;
	left: 0;

	display: flex;
	align-items: center;
	justify-content: center;

	width: 100vw;
	height: 100vh;

	transition: all 0.4s;

	background: rgb(0, 0, 0, 0.5);

	&.isOpen {
		top: 0;
	}

	.close {
		position: absolute;
		top: 20%;
		right: 20%;

		cursor: pointer;

		@media (max-width: 768px) {
			right: 5%;
		}

		@media (max-width: 425px) {
			top: 30%;
		}
	}

	.slider {
		width: 50%;

		@media (max-width: 768px) {
			width: 80%;
		}

		.img {
			width: 100%;
		}
	}
}
