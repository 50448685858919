@import '../../../../styles/variables.scss';

.faq {
	max-width: 1440px;
	margin-bottom: 20px;

	min-height: 50vh;

	@media (max-width: 425px) {
		margin-bottom: 40px;
	}

	.title {
		position: relative;
		margin: 50px 0;
		height: max-content;
		text-align: center;

		font-size: 50px;
		font-weight: normal;
		line-height: 46px;

		&::before {
			@include bg-dots;
		}

		@media (max-width: 425px) {
			font-size: 40px;
		}
	}

	.descr {
		display: grid;

		max-width: 600px;
		gap: 20px;

		&::before {
			@include rect-2-right;
		}

		@media (max-width: 768px) {
			max-width: 100%;
		}

		.accordion {
			width: 600px;
			justify-self: center;

			@media (max-width: 768px) {
				width: 80%;
			}
		}
	}
}