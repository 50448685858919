@import '../../../../styles/variables.scss';


.wrapper {
    border-radius: 10px;
    width: 150px;
    height: 150px;
    @include primary-border;
    position: fixed;
    z-index: 9;
    left: 10px;

    @media(max-width:768px) {
        display: none;
    }

    .container {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 100%;
        height: 100%;

        .link {
            font-weight: 300;
            font-size: 16px;
            line-height: 21px;
            color: $grey;
            cursor: pointer;
            padding: 0 20px;
            width: 100%;

            &:hover {
                background: $hover-primary;
                -webkit-background-clip: text !important;
                -moz-background-clip: text !important;
                -webkit-text-fill-color: transparent !important;
                -moz-text-fill-color: transparent !important;
            }

            &.activeM {
                background: $hover-primary;
                -webkit-background-clip: text !important;
                -moz-background-clip: text !important;
                -webkit-text-fill-color: transparent !important;
                -moz-text-fill-color: transparent !important;
            }

            &.activeF {
                background: $hover-primary;
                -webkit-background-clip: text !important;
                -moz-background-clip: text !important;
                -webkit-text-fill-color: transparent !important;
                -moz-text-fill-color: transparent !important;
            }

            &.activeS {
                background: $hover-primary;
                -webkit-background-clip: text !important;
                -moz-background-clip: text !important;
                -webkit-text-fill-color: transparent !important;
                -moz-text-fill-color: transparent !important;
            }
        }

    }

}