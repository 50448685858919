@import '../../../../styles/variables.scss';

.wrapper {
    margin: 110px 0;

    .title {
        font-size: 50px;
        font-weight: normal;
        line-height: 46px;
        text-align: center;
        color: white;

        &.dots {
            position: relative;

            &::before {
                @include bg-dots;
                opacity: 0.6;
            }
        }

        @media (max-width: 425px) {
            font-size: 40px;
        }

        @media (max-width: 320px) {
            font-size: 35px;
        }
    }

    .img {
        text-align: center;
        margin: 70px 0;
        position: relative;
        width: 100%;

        &.dots {
            &::before {
                @include bg-dots;
                opacity: 0.6;
            }
        }

        &.cloudImg {
            img {
                width: initial !important;
            }
        }

        @media (max-width: 768px) {
            img {
                width: 90%;
            }

            &.resetImg img {
                width: initial !important;
            }
        }
    }

    .descr {
        font-weight: 300;
        font-size: 16px;
        line-height: 26px;
        color: #DDD9E3;
        max-width: 600px;
        margin: 0 auto;
        text-align: center;
    }
}