@import '../../../styles/variables.scss';

.layout {

	.nav_wrapper {
		position: relative;

		height: 20vh;
		padding: 0;

		.logo_wrapper {
			display: grid;
			align-items: center;

			border-radius: 0;
			border-top-left-radius: 0 !important;
			border-top-right-radius: 0 !important;
			grid-template-columns: auto 1fr;
			gap: 16px;
			margin-top: 10px;
			padding: 0 20px;
			// @include primary-background;

			&.tablet {
				grid-template-columns: auto 1fr auto;
			}

			.logo {
				width: 100%;
				margin: auto;
			}

			.CES {
				// padding: 25px;
				// background-color: #0A1E3E;
				// border-radius: 10px;
				// display: flex;
				// justify-content: center;

				@media (max-width:500px) {
					padding: 10px;
				}

				.content {
					display: flex;
					align-items: center;

					&>*+* {
						margin-left: 20px;
					}

					.icons {
						&>*+* {
							margin-left: 20px;
						}
					}

					p {
						font-size: 15px;
						line-height: 15px;

						span {
							font-weight: bold;
						}
					}

					@media (max-width:500px) {
						flex-direction: column;
						text-align: center;

						&>*+* {
							margin-left: 0;
							margin-top: 10px;
						}
					}
				}

			}
		}

		@media (max-width: 768px) {
			height: 10vh;
		}

		.nav {
			display: grid;
			align-items: center;

			// margin-top: 40px;

			transition: all 0.4s;
			text-align: center;
			grid-template-columns: repeat(7, auto);

			@media (max-width: 768px) {
				position: absolute;
				z-index: 10;
				top: 5vh;
				left: -100%;

				justify-content: center;

				padding: 20px;

				text-align: left;

				border: 1px solid $grey;
				border-left: hidden;
				border-top-right-radius: 8px;
				border-bottom-right-radius: 8px;
				background: #000;
				grid-template-columns: auto;
				gap: 10px;

				li {
					a {
						color: #000;
						background: $primary;
						-webkit-background-clip: text !important;
						-moz-background-clip: text !important;

						font-weight: 500;
						-webkit-text-fill-color: transparent !important;
						-moz-text-fill-color: transparent !important;
					}
				}

				&.active {
					left: 0;
				}
			}

			.other {
				color: $white;
				position: relative;
				font-size: 16px;
				line-height: 21px;
				cursor: pointer;
				transition: all 0.4s;

				svg {
					transition: all 0.4s;
				}

				&:hover {
					span {
						text-decoration: none;
						background: $hover-primary;
						-webkit-background-clip: text !important;
						-moz-background-clip: text !important;
						-webkit-text-fill-color: transparent !important;
						-moz-text-fill-color: transparent !important;

						svg {
							transform: rotate(180deg);
						}

						@media (max-width: 768px) {
							color: #000;
							background: $primary;
							-webkit-background-clip: text !important;
							-moz-background-clip: text !important;

							font-weight: 500;
							-webkit-text-fill-color: transparent !important;
							-moz-text-fill-color: transparent !important;
						}
					}
				}

				.hoverBlock {
					position: absolute;
					transition: all 0.4s;
					background: #000;
					padding: 1rem;
					border: 1px solid #fff;
					border-radius: 8px;
					border-top: 0;
					top: 20px;
					left: -10%;
					width: max-content;
					opacity: 0;
					z-index: -10;

					a {
						margin: 5px;
						display: block;
					}

					&.hovered {
						opacity: 1;
						z-index: 9;
					}
				}


			}
		}
	}

	.container {
		padding: 0 2rem;
		display: flex;
		justify-content: center;
	}



	.footer {
		padding: 0 2rem;
		display: grid;

		margin: 50px 0;

		@media (max-width: 1024px) {
			margin: 0 0 30px 0;
		}

		.prefooter {
			&::before {
				@include rect-2-left;
				transform: translateY(-60%);
			}

			display: grid;
			grid-template-columns: auto 1fr auto;

			align-items: center;
			gap: 40px;

			padding: 10px 45px;

			a {
				justify-self: center;
			}

			p {
				text-align: center;
				word-spacing: 3px;

				font-size: 15px;
				font-weight: 300;
				line-height: 21px;

				a {
					display: grid;

					width: max-content;
					margin: 0 auto;

					span {
						width: max-content;
						margin: 0 auto;
					}

					@media (max-width: 425px) {
						display: initial;

						margin: 0;
					}
				}
			}

			@media (max-width: 768px) {
				padding: 10px;
			}

			@media (max-width: 600px) {
				gap: 10px 0;

				padding: 5px;
				grid-template-columns: auto auto;

				p {
					grid-row: 2;
					grid-column-start: 1;
					grid-column-end: 3;
				}
			}

			@media (max-width: 375px) {
				padding: 5px 10px;
			}
		}

		.footerBottom {
			display: grid;

			margin: 30px 0 0 0;
			grid-template-columns: auto 1fr;
			gap: 40px;

			.icons {
				display: grid;
				grid-template-columns: repeat(7, 1fr);
				gap: 25px;
			}

			.terms {
				display: grid;
				align-items: center;
				grid-template-columns: repeat(6, auto);
				gap: 30px;

				.term {
					cursor: pointer;
					text-decoration: underline;
					justify-self: center;
				}
			}

			@media (max-width: 1024px) {
				display: grid;
				grid-template-columns: 0.5fr 1fr;
				gap: 50px;

				.icons {
					display: grid;
					align-items: center;
					grid-template-columns: repeat(7, 1fr);

					span {
						font-size: 15px;
						font-weight: 300;
						line-height: 18px;
						grid-column: 1/7;
					}
				}

				.terms {
					display: grid;
					align-items: center;
					justify-content: center;

					font-size: 14px;
					grid-template-columns: repeat(3, 1fr);
					gap: 30px 0;
				}
			}
		}

		//для таблета в 768 (параллель с навигацией)
		.tablet {
			display: grid;

			padding: 28px 0 0 0;
			grid-template-columns: 1fr 0.5fr;

			.tabletTerms {
				display: grid;
				grid-template-columns: repeat(6, auto);
				grid-column: 1/3;

				.term {
					width: 100%;

					cursor: pointer;
					text-decoration: underline;

					font-size: 14px;
					justify-self: center;
				}
			}

			.tabletBottom {
				display: grid;

				margin-top: 45px;
				grid-template-columns: 1fr auto;
				grid-column: 1/-1;

				.tabletIcons {
					display: grid;
					grid-template-columns: repeat(7, 0.1fr);
				}

				span {
					font-size: 15px;
					font-weight: 300;
					line-height: 18px;
				}
			}

			@media (max-width: 425px) {
				display: none;
			}
		}

		// мобилка 425
		.mobile {
			display: grid;
			justify-content: center;
			grid-template-columns: 1fr;

			.icons {
				display: grid;

				width: 100%;
				margin: 30px 0;

				text-align: center;
				grid-template-columns: repeat(7, auto);
			}

			span {
				justify-self: center;
			}
		}
	}
}