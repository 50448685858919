@import '../../../../styles/variables.scss';

.press {
	display: grid;
	justify-self: center;
	margin: 50px 0;
	max-width: 1440px;

	.title {
		position: relative;
		font-size: 50px;
		font-weight: normal;
		line-height: 46px;
		margin: 10px 0;

		@media (max-width: 425px) {
			font-size: 40px;
		}
	}

	.descr {
		display: grid;
		grid-gap: 20px;
		max-width: 800px;
		margin: 0 auto;
		margin-top: 30px;

		h2 {
			font-size: 40px;

		}

		p {
			color: $grey;
			font-size: 16px;
			font-weight: 300;
			line-height: 28px;
		}

		&::before {
			@include rect-2-right;
		}

		ul {
			display: grid;
			list-style-type: square;

			li {
				h4 {
					font-size: 30px;
					margin: 10px 0;
					font-weight: 300;
					font-family: $font-title;
				}

				.subTitle {
					font-size: 18px;
					font-weight: bold;
					display: block;
					margin: 10px 0;
				}

				span {
					font-weight: bold;
				}

				p {
					margin-bottom: 30px;
				}
			}
		}
	}

	.download {
		color: #000;
		background: $hover-primary;
		width: 200px;
		margin: 0 auto;
		padding: 10px;
		border-radius: 10px;
		cursor: pointer;
		transition: all 0.4s;
		text-align: center;
		font-weight: 400;
		text-decoration: underline;

		&:hover {
			box-shadow: inset 0px 0px 20px #000;
		}
	}
}