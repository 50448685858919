@import '../../../../styles/variables.scss';

.handicap {
	display: grid;
	justify-content: center;

	max-width: 1440px;
	margin-top: 50px;

	img {
		max-width: 500px;

		@media (max-width: 768px) {
			width: 100%;
		}
	}

	.title {
		margin: 0 auto;

		text-align: center;

		font-size: 50px;
		font-weight: normal;
		line-height: 46px;

		&::before {
			@include rect-2-right;
		}

		@media (max-width: 425px) {
			font-size: 40px;
		}
	}

	.content {
		position: relative;

		display: grid;

		margin: 50px auto;
		grid-template-columns: 1fr auto;
		gap: 35px;

		&::after {
			@include bg-dots;
		}

		@media (max-width: 500px) {
			grid-template-columns: 1fr;
		}

		.descr {
			display: grid;
			align-items: center;
			grid-template-columns: 1fr;
			grid-template-rows: 1fr 1fr 1fr;

			@media (max-width: 500px) {
				align-items: flex-start;
				justify-content: center;
				grid-template-rows: 1fr;
				grid-template-columns: 1fr;
				gap: 20px;
			}

			ul {
				display: grid;

				list-style-type: disc;

				font-size: 16px;
				font-weight: 300;
				line-height: 21px;
				gap: 20px;

				@media (max-width: 500px) {
					font-size: 14px;
					gap: 0;
				}
			}
		}
	}
}
